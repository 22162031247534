import React from "react";

import {
    Otsikko, Teksti, TeknisetTiedot, Rivi, Selite, Arvo, TuoteGrid, ListItem
} from './Komponentit.js';


function Tuote({ tuote, desktop }) {

    const {
        tuotteen_nimi, tuotteen_esittelyteksti, tuotteen_lyhyt_esittely, telipaino,
        rungon_pituus, rungon_korkeus_maasta, rengaskoko, omapaino, lisavarusteet,
        lavan_pituudet, korkeus_lavan_pohjaan_maasta, kokonaispaino, kippauskulma,
        kantavuus, aisapaino
    } = tuote;

    return (
        <div>
            <TuoteGrid desktop={desktop}>
                <div>
                    <Otsikko>{tuotteen_nimi}</Otsikko>
                    <p>{tuotteen_lyhyt_esittely}</p>
                    <Teksti>
                        {tuotteen_esittelyteksti.map((li, index) => <ListItem key={index}>{li}</ListItem>)}
                    </Teksti>
                </div>
                <div>
                    <Otsikko>Tekniset tiedot</Otsikko>
                    <TeknisetTiedot>
                        <Rivi gray>
                            <Selite>Rungon pituus</Selite>
                            <Arvo>{rungon_pituus} m</Arvo>
                        </Rivi>
                        <Rivi>
                            <Selite>Lavan pituudet</Selite>
                            <Arvo>{lavan_pituudet} m</Arvo>
                        </Rivi>
                        <Rivi gray>
                            <Selite>Rungon korkeus maasta</Selite>
                            <Arvo>{rungon_korkeus_maasta} m</Arvo>
                        </Rivi>
                        <Rivi>
                            <Selite>Korkeus lavan pohjaan maasta</Selite>
                            <Arvo>{korkeus_lavan_pohjaan_maasta} m</Arvo>
                        </Rivi>
                        <Rivi gray>
                            <Selite>Kokonaispaino</Selite>
                            <Arvo>{kokonaispaino} kg</Arvo>
                        </Rivi>
                        <Rivi>
                            <Selite>Omapaino</Selite>
                            <Arvo>{omapaino} kg</Arvo>
                        </Rivi>
                        <Rivi gray>
                            <Selite>Telipaino</Selite>
                            <Arvo>{telipaino} kg</Arvo>
                        </Rivi>
                        <Rivi>
                            <Selite>Kantavuus</Selite>
                            <Arvo>{kantavuus} kg</Arvo>
                        </Rivi>
                        <Rivi gray>
                            <Selite>Aisapaino</Selite>
                            <Arvo>{aisapaino} kg</Arvo>
                        </Rivi>
                        <Rivi>
                            <Selite>Kippauskulma</Selite>
                            <Arvo>{kippauskulma} °</Arvo>
                        </Rivi>
                        <Rivi gray>
                            <Selite>Rengaskoko</Selite>
                            <Arvo>{rengaskoko}</Arvo>
                        </Rivi>
                    </TeknisetTiedot>
                </div>
                <div>
                    <Otsikko>Lisävarusteet</Otsikko>
                    <Teksti>{lisavarusteet.map((li, index) => <ListItem key={index}>{li}</ListItem>)}</Teksti>
                </div>

            </TuoteGrid>
        </div>
    );
}

export default function TuoteSivu({ tuotteet, desktop }) {
    const sorted = tuotteet.sort((a, b) => a.tuotteen_nimi.toLowerCase().localeCompare(b.tuotteen_nimi.toLowerCase()));

    return (
        <TuoteGrid desktop={desktop}>
            {sorted.map((tuote, index) => (
                <Tuote key={index} tuote={tuote} desktop={desktop} />
            ))}
        </TuoteGrid>
    );
}